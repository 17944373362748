.gcontainer{
    position: relative;
    width: 100%;
    height: 100vh
}

.close{
    position: absolute;
    right: 8px;
    top: 20px;
    color: black;
    background-color: #eae8e8;
    border: 1px solid;
    border-style: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

@media  screen and(max-width:500px) {
    .gcontainer{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
    }

    .close{
        top: 60px;
        font-size: 19px;
    }
}
