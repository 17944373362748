.contact_container {
    width: 100vw;
    height: 55vh;
}

.contact_box {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(28, 26, 26);
}

.map {
    width: 100%;
    height: 100%;
}

.info {
    height: 60%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content:  space-evenly;
    flex-wrap: nowrap;
    color: white !important;
}

h1{
    font-size: 3rem;
}

.info li:not(:first-child){
    padding-left: 20px;
    font-size: 1.2rem;
}

.back_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}


    
