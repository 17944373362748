.menu {
  display: flex;
  list-style: none;
  margin-right: 1.6%;
}

.menu.right__menu .open__menu{
  display: none
}

.menu.middle__menu {
  margin-bottom: 0;
  padding-left: 0;
}

.menu.middle__menu a {
  color: #171a20;
  padding: 5px 10px;
  border-radius: 10px;
  transition: all 1s;
}

.menu.middle__menu a:hover {
  background-color: rgba(0,0,0,.07);
  color: #171a20;
}

@media (max-width : 1198px) {
  .menu.middle__menu {
    display: none;
  }
  
  .menu.right__menu .options{
    display: none;
  }
  .menu.right__menu .open__menu{
    display: block;
    background-color:hsla(0,0%,0%,.05);
  }
}

a{
  margin: 0px 20px;
}
.menu li {
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  transition: var(--transition);
}

.menu li:hover {
  background-color: rgba(0,0,0,.07);
  background-color:hsla(0,0%,0%,.05);
}

.menu li a {
  margin: 0 0.5rem;
  font-weight: 500;
  color: var(--black-color);
  cursor: pointer;
}