.container {
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  background-color: black;
}



.video_ads {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 90px;
  display: block;
  display: flex;
  justify-content: center;
  flex-direction: row;
}


.video_mask {
  display: block;
  width: 100%;
  height: 349px;
  position: relative;
  top: 0;
  overflow: hidden;
}

.video_mask video {
  width: 1240px;
  height: 349px;
  /* position: relative; */
  left: 0;
  top: 0;
  display: block;
  margin: auto;
}

.specs {
  position: relative;
  width: 100%;
  margin-top: 90px;
  height: auto;
  z-index: 100 !important;
}

.specs ul {
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  top: 0;
  width: 40%;
  height: auto;
  margin: auto;
}

.specs ul li:first-child {
  border-top: 0;
}

.specs ul li {
  margin: 0;
  padding: 16px 0;
  width: 100%;
  border-top: 1px solid #fff;
  color: #fff !important;
  font-size: 15px;
  box-sizing: border-box;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  font-family: "Gotham SSm", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

}

.specs ul li .title {
  font-weight: normal;
  text-align: left;
  height: auto;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 75%;
  overflow: hidden;
  color: #fff !important;
}

.specs ul li .value {
  font-family: 'Gotham Medium', 'system', sans-serif;
  font-weight: normal;
  text-align: right;
  height: auto;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  max-width: 60%;
  color: #fff !important;
}

.spec_fade {
  z-index: 20;
  position: relative;
}

.aero {
  position: relative;
  width: 100%;
  margin-top: -22%;
  height: auto;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.aero .image {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  justify-content: center;
}

.aero img {
  width: 100%;
  height: auto;
}

.aero .text_content {
  position: absolute;
  bottom: 14%;
  width: 60%;
  color: #fff;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.aero .text_content h1 {
  position: relative;
  width: 44%;
  font-size: 30px;
  font-family: 'Gotham Medium', 'system', sans-serif;
  font-weight: normal;
  color: #fff;
  color: var(--tds-color--white);
}

.aero .text_content p {
  position: relative;
  width: 48%;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  box-sizing: border-box;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero .reserve_btn {
  display: flex;
  align-items: center;
}

.hero span {
  font-size: 16px;
}

.image_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.image_bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero .text_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
}

.hero .text_content p {
  position: absolute;
  margin-top: -60px;
  text-align: center;
  top: 81%;
  left: 50%;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  display: block;
  color: #fff;
  color: var(--tds-color--white);

}

.hero .stats {
  position: absolute;
  height: fit-content;
  width: 50%;
  top: 84%;
  margin: auto;
  position: relative;
  padding-left: 0;


}

.hero .reverse_btn {
  display: flex;
  align-items: center;
}

.hero .stats li {
  position: relative;
  width: 100%;
  height: auto;
  float: left;
  color: #fff;
  color: var(--tds-color--white);
}

.hero .speedometer {
  position: relative;
  height: 30px;
  width: 48px;
  display: inline-block;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transform: scale(0.8);
  transform-origin: 50% 100%;
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

.speedometer_circle {
  position: absolute;
  width: 33px;
  height: 17px;
  bottom: 4px;
  left: 7px;
  overflow: hidden;
}

.speedometer_circle_inner {
  position: absolute;
  width: 33px;
  height: 17px;
  bottom: 0;
  left: 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transform-origin: 50% 100%;
}

.speedometer_needle {
  position: absolute;
  width: 5px;
  height: 19px;
  bottom: 3px;
  left: 21.5px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transform-origin: 50% 15px;
}

.hero .stats li .top {
  position: relative;
  width: 100%;
  height: auto;
  align-items: baseline;
  text-align: center;
  transform: scale(0.9);
  transform-origin: 50% 0;
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.number {
  display: flex;
  align-items: center;
  font-size: 42px;
}

.hero header {
  position: absolute;
  left: 0;
  top: 10%;
  width: 100%;
  padding-top: 0;
  margin-bottom: 0;
  color: black;
}

.hero h2 {
  position: relative;
  font-size: 45px;
  margin-top: 5px;
  font-family: 'Gotham Medium', 'system', sans-serif;
  font-weight: normal;
  line-height: 1;
  display: block;
  text-align: center;
  color: #5c5e62;
}

.hero p {
  top: 81%;
  width: 380px;
  margin-left: -190px;
}

.hero .arrow_down {
  position: absolute;
  bottom: 25px;
  left: 50%;
  width: 20px;
  height: 12px;
  margin-left: -10px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  cursor: pointer;
}

.interio {
  position: relative;
  width: 100%;
  height: fit-content;
}

.interio .image {
  position: relative;
  top: 0;
  width: 100%;
}

.interio .image img {
  width: 80%;
  height: auto;
}

.interio .text_content {
  position: relative;
  width: 80%;
  color: #fff;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.2%;
  display: flex;
  align-items: flex-end;
}

.interio .text_content h1 {
  width: auto;
  font-size: 24px;
  margin-right: 42px;
  padding-top: 0;
  position: relative;
  float: left;
  font-family: 'Gotham Medium', 'system', sans-serif;
  font-weight: bold;
  color: #fff;
  color: var(--tds-color--white);
  margin-bottom: 0;
}

.interio .text_content p {
  position: relative;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  color: var(--tds-color--white);
  box-sizing: border-box;
  float: left;
  width: auto;
  line-height: 24px;
  margin-bottom: 0;
}

.topview {
  position: relative;
  width: 100%;
  margin-top: 0;
  height: auto;
}

.topview .image {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.topview img {
  width: 150vw;
  height: auto;
}

.topview .text_content {
  position: absolute;
  bottom: 7%;
  width: 30%;
  color: #fff;
  text-align: left;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topview h1 {
  width: auto;
  font-size: 20px;
  margin-right: 42px;
  padding-top: 0;
  position: relative;
  float: left;
  font-family: 'Gotham Medium', 'system', sans-serif;
  font-weight: normal;
  color: #fff;
  color: var(--tds-color--white);
  text-align: center;
}

.topview p {
  position: relative;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  color: var(--tds-color--white);
  box-sizing: border-box;
  float: left;
  width: 80%;
  line-height: 24px;
}

.unveil {
  margin-top: 10vw;
}

.unveil .image {
  margin-left: auto;
  margin-right: auto;
  max-width: 1060px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  width: 100%;
  height: auto;
}

.unveil .video-wraper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.unveil .text_content {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #fff;
}

.unveil iframe {
  width: 100%;
  height: 500px;
}

.vline {
  position: relative;
  margin-top: 20px;
  width: 1px;
  height: 195px;
  left: 50%;
  top: 0;
  display: block;
  background-color: #fff;
  opacity: .4;
}

.bottom_cta {
  position: relative;
  margin-top: 20px;
  margin-bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 133px;
}

.g_order_button {
  position: relative;
  display: block;
  width: 178px;
  height: 42px;
  box-sizing: border-box;
  border: 2.5px solid #fff;
  border-radius: 4px;
  font-size: 11px;
  line-height: 13px;
  color: #fff;
  text-align: center;
  padding-top: 12px;
  font-family: 'Gotham Medium', 'system', sans-serif;
  font-weight: normal;
  transition: background-color .5s, opacity .5s;
  cursor: pointer;
  pointer-events: auto;
  font-weight: bold;
}

.g_order_button:hover {
  background-color: #fff;
  color: #5c5e62;
}

.lastP {
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 80px;

}

.tds_text_caption {
  width: 32%;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.tds_link {
  --tds-link--icon-margin: calc((var(--tds-icon--size) + var(--tds-size--half)) * -1);
  border: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  text-align: initial;
  color: var(--tds-text--color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  text-decoration: underline;
}


@media screen and (max-width: 1500px) {
  .hero .stats {
    width: 60%;
  }
}

@media screen and (max-width: 780px) {
  .hero .stats {
    top: 75%;
  }

  .hero .text_content p {
    top: 72%;
  }
}

@media screen and (max-width: 1400px) {

  .aero .text_content {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .interio .text_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: -10%;
  }

  .aero .text_content {
    bottom: -4%;
  }

  .specs ul {
    width: 90% !important;
  }

  .aero .text_content h1 {
    width: 64% !important;
    font-size: 23px !important;
  }

  .aero .text_content p {
    width: 64% !important;
    text-align: start;
  }

  .topview {
    display: flex;
    justify-content: center;
  }

  .topview .text_content {
    position: absolute;
    bottom: -2%;
    margin-left: 0;
    width: 70%;
  }

  .topview .text_content h1 {
    width: 64% !important;
    font-size: 23px !important;
    text-align: left;
  }
  .topview .text_content p{
    width: 64% !important;
  }

  .interio {
    margin-top: 10%;
  }
}


@media screen and (max-width: 800px) {
  .aero {
    flex-direction: column;
  }
  .aero .image {
    margin-top: 30px;
  }

  .specs ul {
    width: 90% !important;
  }

  .aero .text_content {
    position: relative;
  }
}

@media screen and (max-width: 500px) {

  .specs ul li {
    padding: 7px 0 !important;
    font-weight: 300 !important;
    font-size: 13px !important;
  }

  .hero .stats {
    top: 70% !important;
    width: 90% !important;
  }

  .aero img {
    width: auto;
    height: 120vw;
  }

  .aero .text_content p,  
  .topview .text_content p {
    margin-left: 0px;
    font-size: 15px;
    padding-left: 0;
    width: 71% !important;
  }

  .interio .text_content p {
    font-size: 15px;
    width: 71% !important;
    margin-top: 5%;
  }

  .hero .text_content p {
    top: 70% !important;
  }

  .video_mask video {
    object-fit: cover !important;
  }

  .video_mask {
    width: 100% !important;
  }

  .aero .text_content h1 {
    width: 71% !important;
    font-size: 23px !important;
  }

  .interio .text_content {
    margin-top: 5%;
  }

  .interio .text_content h1 {
    width: 71% !important;
    font-size: 23px !important;
  }

  .interio img {
    width: 100% !important;
    height: 70vw !important;
  }

  .topview {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .topview .text_content {
    position: relative !important;
    margin-left: 0;
    width: 90% !important;
  }

  .topview .text_content h1, topview .text_content p {
    width: 100% !important;
    font-size: 23px !important;
    text-align: left;
  }

  .tds_text_caption {
    width: 100%;
  }

  .specs {
    margin-top: 0px;
  }

  .unveil {
    margin-top: -20vw !important;

  }

  .unveil .image {
    padding: 0;
  }

  .unveil .text_content {
    margin-top: -124px;
    font-size: 14px !important;
    font-weight: 300 !important;
  }

  .vline {
    height: 125px;
    margin-top: 20px;
  }

  .g_order_button {
    width: 68%;
  }

  .lastP {
    padding: 50px 0;
    margin-bottom: 0;
  }

  .tds_link {
    text-decoration-line: none;
  }
}

@media screen and (max-width: 414px) {
  .hero .text_content p {
    font-size: 13px !important;
  }

  .hero .top,
  .hero .top div {
    font-size: 20px !important;
  }

  .hero div.info {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 356px) {
  .top_view {
    margin-top: 15%;
  }

  .speedometer {
    height: 20px !important;
  }

  .speedometer_circle {
    bottom: 0px;
    left: 1px;
  }

  .speedometer_needle {
    bottom: 0px;
    left: 13.5px;
  }
}
