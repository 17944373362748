:root {
  --transition: .5s;
  --black-color: #171a20;
}
@import url('https://fonts.cdnfonts.com/css/gotham-ssm');

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* color: #393c41; */
}

/* #spec_page{
  font-family: auto !important;
} */
* {
  font-family: "Gotham SSm", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}


/* div,em,p,h1{
  color: white !important;
} */

html {
  -moz-scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

body {
  width: 100%;
  overflow-x: hidden;
}