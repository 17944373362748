.close_wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
}
.btn-close{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: transparent;
  transition: var(--transition);
}
.btn-close:hover{
  background-color:hsla(0,0%,0%,.05);
}