.navbar{
  position: fixed;
  min-height: 60px;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  /* padding: 0 2rem 0 0 ; */
}

.navbar *{
  color: #393c41;
  font-family: "Gotham SSm", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}
.navbar .logo{
  padding: 1.1rem 3rem;
}
@media (max-width : 1198px) {
  .navbar .logo{
    padding: 1.1rem;
    padding-top: 5px;
  }
}
.navbar .logo img{
  height: 0.75rem;
}