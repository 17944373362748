.info-container{
  padding: 15vh 0 0;
  text-align: center;
  transition: .5s;
}
.info-container .header{
  font-size: 40px;
  font-weight: 500 !important ;
  margin: 0 0 0.5rem 0;
  color: var(--black-color);
}
.info-container a{
  position: relative;
  text-decoration: none;
  box-shadow: 0 1px 0 0 var(--black-color);
  transition: 0.3s;
  color: var(--black-color);
}
.info-container a:hover{
  box-shadow: 0 1.5px 0 0 var(--black-color);
  color: var(--black-color);
}