.section{
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  scroll-snap-align: start;
  -moz-scroll-snap-stop:always;
  scroll-snap-stop: always;
  overflow-x: hidden;
}
.section .buttons{
  width: 100%;
  transition: .5s;
}
.section .btn-group{
  margin: 0 0 156px 0;
  display: flex;
  justify-content: center;
}
.section .btn-group.first__section{
  margin: 0 0 100px 0;
}
.section .btnScrollIntersectionAnimation{
  display : flex;
}
@media (max-width : 578px) {
  .section .btnScrollIntersectionAnimation{
    flex-direction: column;
  }
  .section .btn-group > * > * > *{
    width: 90%;
  }
  .section .btnScrollIntersectionAnimation{
    width: 100%;
  }
}
.down-arrow{
  margin: 0 0 20px 0;
  height: 24px;
  width: 14px;
  animation: animateDown 2s infinite;
}
@keyframes animateDown {
  0%,20%,50%,80%,100%{
    transform: translateY(0);
  }
  40%{
    transform: translateY(5px);
  }
  60%{
    transform: translateY(3px);
  }
}